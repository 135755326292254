import React from "react"
import Image from "../Image"
import Title from "../Title"
import Desc from "../Desc"
import './index.scss'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {normalizeRelativeLink} from '../../helpers/utils';

function HoverContent({title, desc, location, year, status}) {
   return <div className={"HoverContent"}>
      <Title.Small children={title}/>
      <Desc.Small children={`${location}, ${year}`}/>
   </div>
}


function SpecialGridImage({className, image, imageFluid, title, desc, href, ...props}) {
  if (!imageFluid) {
    return null
  }
   return (
      <Link className={`SpecialGridImage ${className}`} to={href}>
        <Img fluid={imageFluid} />
        <HoverContent title={title} desc={desc} {...props}/>
      </Link>
   )
}

function EmptyCells({count, startCounterFrom, image}) {
   let cells = [];

   for (let i = 0; i < count; i++) {
      cells.push(
         <SpecialGridImage
            key={i.toString()}
            className={`im-${startCounterFrom + i} no-visible`}
            image={image}
            href={"/#"}/>
      )
   }

   return cells;
}


const GRID_RANGES = [0, 3, 7, 11, 13];

function findSiblingHeightNum(range, number) {
   let prevNum = 0;

   for (let i = 0; i < range.length; i++) {
      let nextNum = GRID_RANGES[i];

      if (nextNum >= number) {
         return nextNum;
      }

      prevNum = nextNum;
   }

   return prevNum;
}

export default function GridImages({collection}) {
   let total = collection.length;
   let needCreateCount = findSiblingHeightNum(GRID_RANGES, total);
   let emptyCellsCount = needCreateCount - total;

   return (
      <div className={`GridImages count-${collection.length}`}>
         {
            collection.map((page, index) => {
              const shouldBeLarge = index + 1 === 3 || index + 1 === 12
               return <SpecialGridImage className={`im-${index+1}`}
                                 key={page.id}
                                 href={`/work/${page.slug}`}
                                 desc={page.description}
                                 image={page.hero && page.hero.url}
                                 imageFluid={page.hero && shouldBeLarge ? page.hero.large.childImageSharp.fluid : page.hero.small.childImageSharp.fluid}
                                 year={page.year}
                                 location={page.location}
                                 status={page.status}
                                 title={page.title}/>})
         }

         {!!total &&
         <EmptyCells
            count={emptyCellsCount}
            startCounterFrom={total + 1}
            image={collection[0].hero && collection[0].hero.url}/>}

      {/*      <SpecialGridImage className={'im-1'}/>
      <SpecialGridImage className={'im-2'}/>
      <SpecialGridImage className={'im-3'}/>

      <SpecialGridImage className={'im-4'}/>
      <SpecialGridImage className={'im-5'}/>
      <SpecialGridImage className={'im-6'}/>
      <SpecialGridImage className={'im-7'}/>

      <SpecialGridImage className={'im-8'}/>
      <SpecialGridImage className={'im-9'}/>
      <SpecialGridImage className={'im-10'}/>
      <SpecialGridImage className={'im-11'}/>

      <SpecialGridImage className={'im-12'}/>
      <SpecialGridImage className={'im-13'}/>*/}
      </div>
   )
}
