


export let sortByOrder = (a, b) => (+(a.order || 0) - +(b.order|| 0));
export let sortByReverseOrder = (a, b) => !sortByOrder(a, b);

export let sortByKey = (list, key) => list.sort(function(a, b){
    if(a[key] < b[key]) { return -1; }
    if(a[key] > b[key]) { return 1; }
    return 0;
});
