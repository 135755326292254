import React from 'react';

import Layout from '../../../components/Layout';
import SEO from '../../../components/SEO';
import './index.scss';
import DropDownsContainer from '../../../components/DropDownsContainer';
import DropDown from '../../../components/DroDown';
import Desc from '../../../components/Desc';
import Slider from '../../../components/Slider';
import Footer from '../../../components/Footer';
import {WebsiteLink} from '../../../components/WebsiteLink';
import {graphql, Link} from 'gatsby';
import {replaceSpacesTo} from '../../../helpers/textFormatter';
import injectComponents from '../../../helpers/injectReactComponents';
import isActive from '../../../pipes/users';
import {sortByKey} from '../../../utils/sort';
import _ from 'lodash';
import {getImageNameId, IMAGE_SIZES} from '../../../helpers/utils';


let slides = [
    () => 'Default slide 1',
    () => 'Default slide 2',
];

function CollaboratorsHeader({items, headerClassName}) {
    return (<div className={`${headerClassName} TableHeader`}>
        {items.map(({label, className}) => (<p className={` ${className}`} key={label}>{label}</p>))}
    </div>);
}

function CollaboratorsRow({items, data, isActive}) {
    return (
        <div className={`CollaboratorsRow TableRow ${isActive ? 'active' : ''} `}>
            {items.map(({key, className}, index) => (<p className={`${className}`} key={index}>{data[ key ]}</p>))}
        </div>
    );
}


let tableConfig = {
    header: {className: 'CollaboratorsHeader'},
    items: [
        {label: 'COLLABORATOR', className: 'name', key: 'fullName'},
        {label: 'FIELD OF EXPERTISE', className: 'expertise', key: 'job'},
    ],
};

export function Table({config, data, onSelect, DetailsRow, filesMap = {}, activeIndex}) {

    return (
        <div className={`Table ${config.className}`}>
            <CollaboratorsHeader items={config.items}
                                 headerClassName={config.header.className}/>
            <div className="TableContent">
                <DropDownsContainer activeIndex={activeIndex} isSingleOpen={true} onToggle={onSelect}>
                    {data.map((itemData, i) => {

                        return (
                            <DropDown
                                key={i.toString()}
                                classNamePrefix="collaborator-drop-down"
                                showArrow={false}
                                header={<CollaboratorsRow
                                    items={config.items}
                                    isActive={itemData.isActive}
                                    data={data[ i ]}/>}>

                                <DetailsRow data={data[ i ]} filesMap={filesMap}/>
                            </DropDown>);
                    })}
                </DropDownsContainer>
            </div>
        </div>
    );
}

Table.defaultProps = {
    activeIndex: null,
    onSelect: () => {
    },
};


export function CollaboratorsRowContent({data = {}, filesMap = {}, children, linkName = 'VIEW FULL CASE STUDY'}) {
    let {description, slug, link, sliderTemplate, extractImages} = data;
    let fluidMap = {};


    ;(extractImages || []).forEach(data => {
        let fluid = _.get(data, 'childImageSharp.fluid');

        if (!fluid) return null;

        fluidMap[ `${getImageNameId(fluid.src)}_${IMAGE_SIZES.MEDIUM}` ] = fluid;
    });

    Object.keys(filesMap).forEach((hash) => {
        let value = fluidMap[`${filesMap[hash].replace(/\s/g, '%20')}_${IMAGE_SIZES.MEDIUM}`];
        fluidMap[`${hash}_${IMAGE_SIZES.MEDIUM}`] = value
    });

    return (
        <div className='RowContent row'>
            <div className="additional-info">
                <Desc.Normal>
                    {description || `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut felis ac enim sodales consequat, Etiam
               tincidunt sagittis. Quisque fringilla, sapien a rhoncus lobortis, sem odio maximus ante, nec imperdiet
               odio purus id augue. Pellentesque neque ipsum, dapibus eleifend tempor vitae, pulvinar ut velit.`}
                </Desc.Normal>

                {(link || slug) && <WebsiteLink
                    name={linkName}
                    link={link ? link : `/work/${slug}`}/>}
            </div>

            {sliderTemplate ? <div className="injected-data">
                <div className={'wrap-inject-slider'}> {injectComponents(sliderTemplate, {}, {fluidMap})}</div>
            </div> : <Slider slides={slides}/>}

            {children}

        </div>
    );
}


function CollaboratorProjects({projects}) {

    return (<div className="CollaboratorProjects">
        <p className="CollaboratorProjectsTitle">projects</p>
        {
            projects.map((project, key) => (
                <Link key={key}
                      to={`work/${project.slug}`}
                      children={project.title}/>),
            )
        }
    </div>);
}

const StudioCollaborators = ({data, location, pageContext}) => {
    let {filesMap} = pageContext;
    let {cms: {collaborators}} = data;
    let people = sortByKey(collaborators, 'fullName').filter(isActive);
    let selectedUser = location.hash.replace('#', '');
    let activeIndexUser = null;

    if (selectedUser) {
        people.forEach(({fullName, ...person}, index) => {
            if (selectedUser === replaceSpacesTo(fullName)) {
                activeIndexUser = index;
            }
        });
    }


    return (
        <>
            <Layout className={'StudioCollaborators'} Footer={<Footer/>}>
                <SEO title="Home" keywords={[]}/>

                <Table config={tableConfig}
                       activeIndex={activeIndexUser}
                       DetailsRow={props => (
                           <CollaboratorsRowContent {...props} linkName="WEBSITE" filesMap={filesMap}>
                               {!!props.data.casestudies.length &&
                               <CollaboratorProjects projects={props.data.casestudies}/>}
                           </CollaboratorsRowContent>)
                       }
                       data={people}/>
            </Layout>
        </>
    );
};


export const query = graphql`
{
  cms {
    collaborators {
      fullName
      job: fieldOfExpertise
      link
      sliderTemplate : allCollaboratorsImage
      description : allCollaboratorsDescription
      extractImages {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 90) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      active
      casestudies {
        slug
        title
      }
    }
  }
}`;


export default StudioCollaborators;
