import React from "react"
import './index.scss'
import Layout from "../../components/Layout/index"
import SEO from "../../components/SEO/index"
import GridImages from "../../components/GridImages/index"
import Button from "../../components/Button/index"
import DropDownsContainer from "../../components/DropDownsContainer/index"
import DropDown from "../../components/DroDown/index"
import {filterByNotArchived} from "../../utils/filter";
import _ from 'lodash'
import {CollaboratorsRowContent, Table} from '../studio/collaborators';

let tableConfig = {
   className: 'all-projects',
   header: {className: "CollaboratorsHeader"},
   items: [
      {label: 'project', className: 'project', key: 'title'},
      {label: 'year', className: 'year', key: 'year'},
      {label: 'typology', className: 'typology', key: 'typology'},
      {label: 'location', className: 'location', key: 'location'},
      {label: 'status', className: 'status', key: 'status'}
   ]
};

const MAX_GRID_IMAGES = 13;

function sliceArrayByCount(arr, count) {
   let grids = [];

   for (; arr.length;) {
      grids.push(arr.splice(0, count))
   }

   return grids;
}

const Page = ({pageContext}) => {
   let {siteMap, posts, category, tag, filesMap} = pageContext;
   let dropDownHeader = `All ${tag || category || ''} projects`;

   posts = _.sortBy(posts, ({id}) => +id);
   posts = _.sortBy(posts.reverse(), ['order']);

   let notArchivedPosts = posts.filter(filterByNotArchived);
   let grids = sliceArrayByCount([...notArchivedPosts], MAX_GRID_IMAGES);

    return (
      <Layout siteMap={siteMap} className="Work">
         <SEO title="Home" keywords={[`gatsby`, `application`, `react`]}/>
         {
            grids.map((list, key) =>  <GridImages collection={list} key={key}/>)
         }

         <DropDownsContainer bottomBorder={true}>
            <DropDown header={dropDownHeader}>
               <Table data={posts.map(post => ({...post, slug: post.archived ? null : post.slug}))}
                      config={tableConfig}
                      filesMap={filesMap}
                      DetailsRow={CollaboratorsRowContent}
               />
            </DropDown>
         </DropDownsContainer>
         <Button.Top/>
      </Layout>
   )
};

export default Page
